import React from "react";

import leaf404 from "../static/404leaf.png";

const GenericNotFound = () => {
  return (
    <div className="row px-4 py-2 align-items-center">
      <img src={leaf404} height="56" alt="" />
      <h3 className="my-0 mx-3 text-dark">Page not found</h3>
    </div>
  );
};

export default GenericNotFound;
