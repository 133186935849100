import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";

import { persistAuth } from "../redux";

import logo from "../static/android-chrome-192x192.png";

const Navbar = ({ username, resetAuth }) => {
  let history = useHistory();

  const handleSignOut = () => {
    history.push("/");
    resetAuth();
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
      <NavLink className="navbar-brand mr-4" exact to="/">
        <img src={logo} height="36" alt="" />
        <span className="mx-1">SmartStream Devicetory</span>
      </NavLink>
      {username && (
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" exact to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" exact to="/stats/">
                Stats
              </NavLink>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/change-password/">
                <FontAwesomeIcon icon={faUserEdit} className="mr-1" />
                {username}
              </NavLink>
            </li>
          </ul>
          <button className="btn btn-outline-light ml-2" onClick={handleSignOut}>
            Sign Out
          </button>
        </div>
      )}
    </nav>
  );
};

const mapStateToProps = (state) => ({ username: state.username });
const mapDispatchToProps = (dispatch) => ({
  resetAuth: () => dispatch(persistAuth({ token: null, expiration: null, username: null })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
