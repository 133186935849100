import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { DevicetoryAPI as api } from "../api";
import { Device, Room, Location } from "./Reusables";

const UserPage = () => {
  const { user_id } = useParams();

  const [user, setUser] = useState(null);
  const [locations, setLocations] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [devices, setDevices] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const userResp = await api.get(`/users/${user_id}`);
      if (userResp.status === "ok") {
        setUser(userResp.data);
        const locationsResp = await api.get(`/locations?owner_id=${user_id}`);
        if (locationsResp.status === "ok") setLocations(locationsResp.data);
        const roomsResp = await api.get(`/rooms?owner_id=${user_id}`);
        if (roomsResp.status === "ok") setRooms(roomsResp.data);
        const devicesResp = await api.get(`/devices?owner_id=${user_id}`);
        if (devicesResp.status === "ok") setDevices(devicesResp.data);
      } else
        setError(
          <h3>
            User id <small>'{user_id}'</small> not found
          </h3>
        );
    };

    fetchData();
  }, [user_id]);

  return user ? (
    <>
      <div className="row align-items-center mb-2">
        <div className="col-auto">
          <h3>
            {user.first_name} {user.last_name}
          </h3>
        </div>
        <div className="col-auto">
          <span className="d-block">
            <FontAwesomeIcon icon={faEnvelope} className="fa-sm text-secondary" />
            {user.email}
          </span>
        </div>
      </div>
      {(locations || []).map((location) => (
        <Location key={location._id} location={location}>
          {(rooms || [])
            .filter((room) => room.location_id === location._id)
            .map((room) => (
              <ul key={room._id} className="list-group list-group-horizontal-md mb-2">
                <li className="list-group-item">
                  <Room room={room} header={true} />
                </li>
                {(devices || [])
                  .filter((device) => device.room_id === room._id)
                  .map((device) => (
                    <li key={device._id} className="list-group-item">
                      <Device device={device} header={true} />
                    </li>
                  ))}
              </ul>
            ))}
        </Location>
      ))}
    </>
  ) : (
    error
  );
};

export default UserPage;
