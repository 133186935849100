import React, { useState, useEffect, useRef } from "react";

import { format, subDays, endOfDay, startOfDay } from "date-fns";
import { DateRangePicker } from "react-date-range";

import { DevicetoryAPI as api } from "../api";

function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const getLabel = (key) => {
  switch (key) {
    case "users":
      return "App Users";
    case "owners":
      return "Device Owners";
    default:
      return capitalize(key).split("_").join(" ");
  }
};

const Stats = () => {
  const [statsTotal, setStatsTotal] = useState({});
  const [statsRecent, setStatsRecent] = useState({});
  const [range, setRange] = useState({
    dateFrom: subDays(new Date(), 6),
    dateTo: new Date(),
  });
  const [pick, setPick] = useState(false);
  const [selection, setSelection] = useState({
    startDate: range.dateFrom,
    endDate: range.dateTo,
    key: "selection",
  });
  const isCancelled = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await api.get("/stats");
      if (!isCancelled.current) setStatsTotal(resp.data);
    };
    fetchData();

    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const dateFrom = startOfDay(range.dateFrom).toISOString();
      const dateTo = endOfDay(range.dateTo).toISOString();
      const resp = await api.get(`/stats?date_from=${dateFrom}&date_to=${dateTo}`);
      if (!isCancelled.current) setStatsRecent(resp.data);
    };
    fetchData();
  }, [range]);

  const handleApplyRange = () => {
    if (pick) setRange({ dateFrom: selection.startDate, dateTo: selection.endDate });
    setPick(!pick);
  };

  const rangeLine = (range) =>
    `${format(range.dateFrom, "yyyy/MM/dd")} – ${format(range.dateTo, "yyyy/MM/dd")}`;

  return (
    <div className="row">
      <div className="col">
        <table className="table stats">
          <thead>
            <tr>
              <th scope="col" className="border-0">
                <h4 className="mb-0">Devicetory Stats</h4>
              </th>
              <th scope="col" className="border-0">
                Total
              </th>
              <th scope="col" className="border-0">
                <span className="d-inline-block mr-2">Recent</span>
                <button className="btn btn-sm btn-light" onClick={handleApplyRange}>
                  {rangeLine(range)}
                </button>
              </th>
            </tr>
            <tr>
              <th colSpan={3} className="p-0">
                {pick && (
                  <div className="calendar">
                    <DateRangePicker
                      ranges={[selection]}
                      onBlur={() => console.log("Out!")}
                      onChange={(v) => setSelection(v.selection)}
                    />
                    <div className="d-flex mb-3 px-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary ml-auto"
                        onClick={() => setPick(false)}>
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary ml-1"
                        onClick={handleApplyRange}>
                        Apply
                      </button>
                    </div>
                  </div>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(statsTotal).map((k, i) => (
              <tr key={i}>
                <th scope="row">{getLabel(k)}</th>
                <td>{statsTotal[k]}</td>
                <td>{statsRecent[k]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Stats;
