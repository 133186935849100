import React from "react";
import { Route, Switch } from "react-router-dom";

import "./App.css";

import PrivateRoute from "./components/PrivateRoute";
import Navbar from "./components/Navbar";
import SignIn from "./components/SignIn";
import ChangePassword from "./components/ChangePassword";
import Search from "./components/Search";
import Stats from "./components/Stats";
import DevicePage from "./components/DevicePage";
import RoomPage from "./components/RoomPage";
import UserPage from "./components/UserPage";
import GenericNotFound from "./components/GenericNotFound";

const App = () => {
  return (
    <>
      <Navbar />
      <div className="container-fluid mt-2">
        <Switch>
          <Route path="/login" component={SignIn} />
          <PrivateRoute exact path="/" component={Search} />
          <PrivateRoute path="/stats/" component={Stats} />
          <PrivateRoute path="/change-password/" component={ChangePassword} />
          <PrivateRoute path="/users/:user_id" component={UserPage} />
          <PrivateRoute path="/rooms/:room_id" component={RoomPage} />
          <PrivateRoute path="/devices/:device_id" component={DevicePage} />
          <PrivateRoute component={GenericNotFound} />
        </Switch>
      </div>
    </>
  );
};

export default App;
