import { createStore } from "redux";

// Actions
export const PERSIST_AUTH = "PERSIST_AUTH";

export const persistAuth = (auth) => {
  return {
    type: PERSIST_AUTH,
    payload: auth,
  };
};

// Reducer

const initialState = () => {
  const state = loadState();
  if (state && state.expiration) {
    const now = new Date();
    const expiration = new Date(state.expiration * 1000);
    if (now < expiration) return state;
  }
  return { rememberMe: state && state.rememberMe };
};

const rootReducer = (state = initialState(), action) => {
  switch (action.type) {
    case PERSIST_AUTH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Local Storage
const STORAGE_KEY = "ssdState";

const loadState = () => {
  try {
    const sessionState = sessionStorage.getItem(STORAGE_KEY);
    if (sessionState === null) {
      const localState = localStorage.getItem(STORAGE_KEY);
      if (localState === null) return undefined;
      return JSON.parse(localState);
    }
    return JSON.parse(sessionState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    if (state.rememberMe) {
      localStorage.setItem(STORAGE_KEY, serializedState);
    } else {
      sessionStorage.setItem(STORAGE_KEY, serializedState);
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ rememberMe: false }));
    }
  } catch {
    console.log("Cannot save application state");
  }
};

// Store
const store = createStore(rootReducer);

const observeStore = (store, onChange) => {
  let currentState = loadState();

  const handleChange = () => {
    let nextState = store.getState();
    if (JSON.stringify(nextState) !== JSON.stringify(currentState)) {
      currentState = nextState;
      onChange(currentState);
    }
  };

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};

observeStore(store, (state) => saveState(state));

export default store;
