import React, { useState } from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { DevicetoryAPI as api } from "../api";
import { persistAuth } from "../redux";

const SignIn = ({ token, rememberMe, persistAuth }) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [rememberValue, setRememberValue] = useState(rememberMe);
  const [error, setError] = useState(null);

  let history = useHistory();
  const { from } = useLocation().state || { from: { pathname: "/" } };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await api.login({ username, password });
    if (response.status === "ok") {
      setError(null);
      persistAuth({
        username,
        token: response.token,
        expiration: response.detail.expiration,
        rememberMe: rememberValue,
      });
      history.replace(from);
    } else setError(response.message);
  };

  return (
    <div className="text-center">
      {token && <Redirect to={from} />}
      {error && <div className="alert alert-danger">{error}</div>}
      <form className="form-signin" onSubmit={handleSubmit}>
        <h1 className="h3 mb-4 font-weight-normal">Please sign in</h1>
        <label className="mb-0 w-100">
          <span className="sr-only">Login</span>
          <input
            type="text"
            className="form-control"
            placeholder="Login"
            required
            autoFocus
            value={username || ""}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <label className="mb-0 w-100">
          <span className="sr-only">Password</span>
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            required
            value={password || ""}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <div className="mb-3">
          <label className="d-flex align-items-center">
            <input
              type="checkbox"
              value="remember-me"
              checked={rememberValue}
              onChange={(e) => setRememberValue(e.target.checked)}
            />
            <span className="ml-1">Remember me</span>
          </label>
        </div>
        <button className="btn btn-lg btn-primary btn-block" type="submit">
          Sign in
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  persistAuth: (auth) => dispatch(persistAuth(auth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
