import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ token, component, ...routeProps }) => {
  return token ? (
    <Route {...routeProps} component={component} />
  ) : (
    <Route
      {...routeProps}
      render={({ location }) => <Redirect to={{ pathname: "/login", state: { from: location } }} />}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({ token: state.token, ...ownProps });

export default connect(mapStateToProps)(PrivateRoute);
