import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFan, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";

import { DevicetoryAPI as api } from "../api";

const SearchResult = ({ item }) => {
  const entity = item.email
    ? `${item.email} : ${item.first_name} ${item.last_name}`
    : `${item.mac} (${item.dev_type})`;

  const location = item.email ? `/users/${item._id}` : `/devices/${item.mac}`;

  return (
    <div className="my-2">
      <FontAwesomeIcon
        icon={item.email ? faUser : faFan}
        className={`mr-1 text-${item.email ? "primary" : "smartstream"}`}
      />
      <NavLink to={location}>{entity}</NavLink>
    </div>
  );
};

const Search = () => {
  const [query, setQuery] = useState(null);
  const [found, setFound] = useState(null);
  const [more, setMore] = useState(null);

  const search = async (e) => {
    e.preventDefault();
    if (query) {
      const resp = await api.get(`/search?query=${encodeURI(query)}`);
      if (resp.status === "ok") {
        setFound(resp.data);
        setMore(resp.detail && resp.detail.more);
      }
    }
  };

  const clear = () => {
    setQuery(null);
    setFound(null);
    setMore(null);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <form className="form-inline" onSubmit={search}>
            <div className="fa-input">
              <input
                className="form-control mr-sm-2 fa-input"
                placeholder="email or device MAC"
                minLength={3}
                aria-label="Search"
                value={query || ""}
                onChange={(e) => setQuery(e.target.value)}
              />
              {query && (
                <i onClick={clear}>
                  <FontAwesomeIcon icon={faTimes} className="fa-sm" />
                </i>
              )}
            </div>
            <button className="btn btn-outline-success my-2 my-sm-0">Search</button>
          </form>
        </div>
      </div>
      <hr className="my-2" />
      <div className="row">
        <div className="col">
          {found && !found.length && (
            <p className="lead">No results found. Try to clarify your query.</p>
          )}
          {(found || []).map((item, i) => (
            <SearchResult key={i} item={item} />
          ))}
          {more && <p className="lead">...and more results found. Try to clarify your query.</p>}
        </div>
      </div>
    </>
  );
};

export default Search;
