import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { DevicetoryAPI as api } from "../api";

const ChangePassword = ({ username }) => {
  const [password, setPassword] = useState(null);
  const [show, setShow] = useState(null);
  const [alert, setAlert] = useState(null);

  let history = useHistory();

  const handleNewPassword = async (e) => {
    e.preventDefault();
    const pwdChangeResp = await api.post("/change-password", { password });
    if (pwdChangeResp.status === "ok") setAlert({ msg: "Password changed", kind: "success" });
    else if (pwdChangeResp.message) setAlert({ msg: pwdChangeResp.message, kind: "danger" });
  };

  const cancel = () => {
    history.goBack();
  };

  return (
    <div className="row">
      <div className="col">
        {alert && <div className={`alert alert-${alert.kind}`}>{alert.msg}</div>}
        <h3>Change Password</h3>
        <form className="form-inline" onSubmit={handleNewPassword}>
          <div className="fa-input">
            <input type="text" hidden defaultValue={username} />
            <input
              className="form-control mr-sm-2 fa-input"
              type={show ? "text" : "password"}
              autoFocus
              required
              placeholder="password"
              minLength={8}
              value={password || ""}
              onChange={(e) => setPassword(e.target.value)}
            />
            {password && (
              <i onClick={() => setShow(!show)}>
                <FontAwesomeIcon icon={show ? faEyeSlash : faEye} />
              </i>
            )}
          </div>
          <button className="btn btn-primary my-2 my-sm-0 mr-2" type="submit">
            Save new password
          </button>
          <button className="btn btn-outline-danger my-2 my-sm-0" type="button" onClick={cancel}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ username: state.username });

export default connect(mapStateToProps)(ChangePassword);
