import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faHistory } from "@fortawesome/free-solid-svg-icons";

import { DevicetoryAPI as api } from "../api";
import { History, OnOff, Room } from "./Reusables";

const RoomPage = () => {
  const { room_id } = useParams();

  const [room, setRoom] = useState(null);
  const [user, setUser] = useState(null);
  const [location, setLocation] = useState(null);
  const [devices, setDevices] = useState(null);
  const [history, setHistory] = useState(null);
  const [show, setShow] = useState("current");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const roomResp = await api.get(`/rooms/${room_id}`);
      if (roomResp.status === "ok") {
        setRoom(roomResp.data);
        const userResp = await api.get(`/users/${roomResp.data.owner}`);
        if (userResp.status === "ok") setUser(userResp.data);
        const locationResp = await api.get(`/locations/${roomResp.data.location_id}`);
        if (locationResp.status === "ok") setLocation(locationResp.data);
        const devicesResp = await api.get(`/devices?room_id=${room_id}`);
        if (devicesResp.status === "ok") setDevices(devicesResp.data);
      } else
        setError(
          <h3>
            Room id <small>'{room_id}'</small> not found
          </h3>
        );
    };

    fetchData();
  }, [room_id]);

  const showHistory = async () => {
    setShow("history");
    if (!history) {
      const historyResp = await api.get(`/room-history/${room_id}`);
      if (historyResp.status === "ok") setHistory(historyResp.data);
    }
  };

  return (
    <div className="row">
      <div className="col-sm-auto mb-2">
        {room && (
          <h3>
            Room <OnOff active={room.active} />
            {room.name}
          </h3>
        )}
        {error}
        {show === "current" && <Room room={room} />}
        {show === "history" && <History data={history} />}
      </div>
      <div className="col-sm-auto mb-2">
        {show === "current" && (
          <button className="btn btn-sm btn-primary" onClick={showHistory}>
            <FontAwesomeIcon icon={faHistory} className="mr-1" />
            Show History
          </button>
        )}
        {show === "history" && (
          <button className="btn btn-sm btn-success" onClick={() => setShow("current")}>
            <FontAwesomeIcon icon={faFileAlt} className="mr-1" />
            Show Current
          </button>
        )}
      </div>
      {room && (
        <div className="col-sm-auto mb-2 ml-auto">
          <div className="card owner-info mb-2">
            <div className="card-body">
              <h5 className="card-title">Property</h5>
              {user ? (
                <div className="card-text">
                  <h6 className="card-subtitle my-2 text-muted">Owner</h6>
                  <NavLink to={`/users/${user._id}`}>
                    {user.first_name} {user.last_name}
                  </NavLink>
                </div>
              ) : (
                <p className="card-text">No owner</p>
              )}
              {location && (
                <div className="card-text">
                  <h6 className="card-subtitle my-2 text-muted">Location</h6>
                  <OnOff active={location.active} />
                  {location.name}
                </div>
              )}
            </div>
          </div>
          {devices && devices.length > 0 && (
            <div className="card owner-info mb-2">
              <div className="card-body">
                <h5 className="card-title">Devices</h5>
                {(devices || []).map((device) => (
                  <div key={device._id} className="card-text">
                    <OnOff active={device.active} />
                    <NavLink to={`/devices/${device.mac}`}>{device.mac}</NavLink>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RoomPage;
