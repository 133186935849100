import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faPowerOff } from "@fortawesome/free-solid-svg-icons";

export const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleString("uk-Ua", { timeZoneName: "short" });
};

const itemsEqual = (a, b) => {
  if (Array.isArray(a) || Array.isArray(b)) return String(a && a.sort()) === String(b && b.sort());
  return a === b;
};

export const OnOff = ({ active }) => {
  const iconClass = `fa-sm mr-1 text-${active ? "smartstream" : "secondary"}`;
  return <FontAwesomeIcon icon={faPowerOff} className={iconClass} />;
};

export const ParamsTable = ({ params }) => {
  const normalize = (param) => {
    if (typeof param.value === "string" && param.value.endsWith("+00:00")) {
      return formatDate(param.value);
    } else if (param.key === 'last_seen') {
      const now = Date.now();
      const passed = Math.round((now - param.value * 1000) / 1000);
      const passed_secs = passed % 60;
      const passed_mins = Math.trunc(passed / 60) % 60;
      const passed_hours = Math.trunc(passed / 3600) % 24;
      const passed_days = Math.trunc(passed / 86400);
      const absolute = new Date(param.value * 1000);
      let textAgo = `${passed_secs} sec ago`;
      if (passed_mins > 0) textAgo = `${passed_mins} min, ${textAgo}`;
      if (passed_hours > 0) textAgo = `${passed_hours} hrs, ${textAgo}`;
      if (passed_days > 0) textAgo = `${passed_days} days, ${textAgo}`;
      return <div>{textAgo}<br />({formatDate(absolute)})</div>;
    } else if (Array.isArray(param.value)) {
      return param.value.map((el, i) => <div key={i}>{el}</div>);
    }
    return String(param.value);
  };

  const valueCellProps = (param) => {
    if (param.modified) return { className: "table-warning", title: "Modified from previous" };
    else return { className: "table-light" };
  };

  return (
    <table className="table table-sm params">
      <tbody>
        {(params || []).map((param) => (
          <tr key={param.key}>
            <td className="text-secondary">{param.key}</td>
            <td {...valueCellProps(param)}>{normalize(param)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const Device = ({ device, header }) => {
  const exclude = [
    "_id",
    "last_update_timestamp",
    "mac",
    "active",
    "room_id",
    "owner",
    "telemetry_uri",
  ];
  const params =
    device &&
    Object.keys(device)
      .filter((key) => exclude.indexOf(key) === -1)
      .map((key) => {
        return { key, value: device[key] };
      });

  return (
    device && (
      <div>
        {header && (
          <>
            <strong className="mr-2">Device:</strong>
            <OnOff active={device.active} />
            <NavLink to={`/devices/${device.mac}`}>{device.mac}</NavLink>
          </>
        )}
        <ParamsTable params={params} />
      </div>
    )
  );
};

export const Room = ({ room, header }) => {
  const exclude = ["_id", "last_update_timestamp", "name", "active", "location_id", "owner"];
  const params =
    room &&
    Object.keys(room)
      .filter((key) => exclude.indexOf(key) === -1)
      .map((key) => {
        return { key, value: room[key] };
      });

  return (
    room && (
      <div>
        {header && (
          <>
            <strong className="mr-2">Room:</strong>
            <OnOff active={room.active} />
            <NavLink to={`/rooms/${room._id}`}>{room.name}</NavLink>
          </>
        )}
        <ParamsTable params={params} />
      </div>
    )
  );
};

export const Location = ({ location, children }) => {
  const coordinates =
    location.latitude || location.longitude
      ? `${String(location.latitude)}, ${String(location.longitude)}`
      : "";

  return (
    <div className="mt-2">
      <strong className="mr-2">Location:</strong>
      <OnOff active={location.active} />
      <span>
        {location.name}
        <i className="ml-2">{coordinates}</i>
      </span>
      {children}
    </div>
  );
};

export const History = ({ data }) => {
  const exclude = ["_id", "id", "mac", "last_update_timestamp", "event"];

  const getParams = (item, i, arr) => {
    const prevItem = arr[i + 1];
    return Object.keys(item)
      .filter((key) => exclude.indexOf(key) === -1)
      .map((key) => {
        return {
          key,
          value: item[key],
          modified: prevItem && !itemsEqual(item[key], prevItem[key]),
        };
      });
  };

  const eventClass = (event) => {
    const colorMap = {
      create: "success",
      update: "info",
      delete: "danger",
    };
    return `badge badge-sm badge-${colorMap[event]} ml-2`;
  };

  return data && data.length ? (
    data.map((item, i, arr) => (
      <div key={item._id}>
        <div className="d-flex align-items-center">
          <span title="Snaphot date">
            <FontAwesomeIcon icon={faClock} className="text-secondary mr-1" />
            {formatDate(item.last_update_timestamp)}
          </span>
          <span className={eventClass(item.event)} title="Snapshot Event">
            {item.event}
          </span>
        </div>
        <ParamsTable params={getParams(item, i, arr)} />
      </div>
    ))
  ) : (
    <div className="lead">No history found</div>
  );
};
