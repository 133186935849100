import store from "./redux";
import { persistAuth } from "./redux";

const resetAuth = () =>
  store.dispatch(persistAuth({ token: null, expiration: null, username: null }));

export const DevicetoryAPI = (() => {
  const baseURL = process.env.REACT_APP_API_URL || "";

  const api = {
    get: async (url) => {
      const response = await fetch(`${baseURL}/internal${url}`, {
        headers: { Authorization: "Bearer " + store.getState().token },
      });
      if (response.status === 401) {
        resetAuth();
        return {};
      }
      return await response.json();
    },
    post: async (url, data) => {
      const response = await fetch(`${baseURL}/internal${url}`, {
        headers: { Authorization: "Bearer " + store.getState().token },
        method: "POST",
        body: JSON.stringify(data),
      });
      if (response.status === 401) {
        resetAuth();
        return {};
      }
      return await response.json();
    },
    login: async (data) => {
      const response = await fetch(`${baseURL}/internal/login`, {
        method: "POST",
        body: JSON.stringify(data),
      });
      return await response.json();
    },
  };

  return api;
})();
