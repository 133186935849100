import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faHistory, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { DevicetoryAPI as api } from "../api";
import { Device, History, OnOff } from "./Reusables";

const DevicePage = () => {
  const { device_id } = useParams();

  const [device, setDevice] = useState(null);
  const [user, setUser] = useState(null);
  const [room, setRoom] = useState(null);
  const [history, setHistory] = useState(null);
  const [show, setShow] = useState("current");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const deviceResp = await api.get(`/devices/${device_id}`);
      if (deviceResp.status === "ok") {
        setDevice(deviceResp.data);
        const userResp = await api.get(`/users/${deviceResp.data.owner}`);
        if (userResp.status === "ok") setUser(userResp.data);
        const roomResp = await api.get(`/rooms/${deviceResp.data.room_id}`);
        if (roomResp.status === "ok") setRoom(roomResp.data);
      } else if (deviceResp.status === "failed") {
        const deviceHwResp = await api.get(`/devices-hardware/${device_id}`);
        if (deviceHwResp.status === "ok") setDevice(deviceHwResp.data);
        else
          setError(
            <h3>
              Device id <small>'{device_id}'</small> not found
            </h3>
          );
      }
    };

    fetchData();
  }, [device_id]);

  const showHistory = async () => {
    setShow("history");
    if (!history) {
      const historyResp = await api.get(`/device-history/${(device && device.mac) || device_id}`);
      if (historyResp.status === "ok") setHistory(historyResp.data);
    }
  };

  return (
    <div className="row">
      <div className="col-sm-auto mb-2">
        {device && (
          <h3>
            Device <OnOff active={device.active} />
            {device.mac}
          </h3>
        )}
        {error}
        {show === "current" && <Device device={device} />}
        {show === "history" && <History data={history} />}
      </div>
      <div className="col-sm d-flex flex-column flex-md-row align-items-start">
        <div>
          {show === "current" && (
            <button className="btn btn-sm btn-primary mr-2 mb-2" onClick={showHistory}>
              <FontAwesomeIcon icon={faHistory} className="mr-1" />
              Show History
            </button>
          )}
          {show === "history" && (
            <button className="btn btn-sm btn-success mr-2 mb-2" onClick={() => setShow("current")}>
              <FontAwesomeIcon icon={faFileAlt} className="mr-1" />
              Show Current
            </button>
          )}
          {device && device.telemetry_uri && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-primary mr-2 mb-2"
              href={device.telemetry_uri}>
              Telemetry Graphs <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          )}
        </div>
        {device && (
          <div className="card owner-info ml-md-auto">
            <div className="card-body">
              <h5 className="card-title">Property</h5>
              {user ? (
                <div className="card-text">
                  <h6 className="card-subtitle my-2 text-muted">Owner</h6>
                  <NavLink to={`/users/${user._id}`}>
                    {user.first_name} {user.last_name}
                  </NavLink>
                </div>
              ) : (
                <p className="card-text">No owner</p>
              )}
              {room && (
                <div className="card-text">
                  <h6 className="card-subtitle my-2 text-muted">Room</h6>
                  <OnOff active={room.active} />
                  <NavLink to={`/rooms/${room._id}`}>{room.name}</NavLink>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevicePage;
